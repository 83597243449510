import styles from "./detailTask.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import i18n from "@i18n";
import { Icon } from "zerocode";
import { appcenterApi, workfluxApi } from "@apis";
import { useParams } from "react-router";
import { history, services } from "@store";
import { serviceTask } from "@services/task";
import { useAsyncEffect, useStore } from "@hooks";
import { AvatarProfileAndInfo } from "components/avatar";
import { FormDocument } from "./components/formDocument";
import { FormApproval } from "./components/formApproval";
import { FormForm } from "./components/formForm";
import { FullFlow } from "./components/fullFlow";
import { DelegateResponsibility } from "./components/delegateReposibility";
import { HistoryTask } from "./components/history";
import { serviceExecutionFlow } from "@services/executionFlow";
import { TaskBanner } from "./components/taskBanner";
import { StatusCanceled } from "components/statusCanceled";
import { Button } from "components/ui";
import { TASK_STATUS } from "@constants";
import { renderName } from "@functions";

/**
 *
 * @param {{
 *     taskProps?: any ;
 * }} props
 */

export function DetailTask({ taskProps }) {
    /**  @type {any} */
    const { taskUid } = useParams();
    const {
        tasks,
        auth,
        users,
        theme: { theme },
    } = useStore();
    /**  @type {any} */
    const [task, setTask] = useState({});

    const [loadingConfirgReview, setLoadingConfirgReview] = useState(false);
    const [isVisibleFullFlow, setIsVisibleFullFlow] = useState(false);
    const [isVisibleTaskBanner, setIsVisibleTaskBanner] = useState(false);
    /** @type {any} */
    const [executionFlow, setExecutionFlow] = useState({});
    const [visibleMessageLink, setVisibleMessageLink] = useState(false);

    const [isDelegateTask, setIsDelegateTask] = useState(false);
    const [isHistoryTask, setIsHistoryTask] = useState(false);

    const clearTask = () => {
        taskProps = null;
        setTask(null);
    };

    const typesTask = {
        approval: {
            component: <FormApproval task={task} clearTask={clearTask} />,
            title: i18n("Aprovação de fluxos"),
        },
        formInput: {
            component: <FormForm task={task} clearTask={clearTask} />,
            title: i18n("Preenchimento de Formulário"),
        },
        documentInput: {
            component: <FormDocument task={task} clearTask={clearTask} />,
            title: i18n("Envio de documento"),
        },
    };
    const type = task?.action?.type?.type;

    const canVisibleTaskBanner = useMemo(() => {
        const uidActionBanner = executionFlow?.workflowObject?.actionBanner;

        const phases = executionFlow?.workflowObject?.phases;

        let actionBanner = {};

        const phaseBanner = phases?.find((phase) =>
            phase?.actions?.find((action) => {
                if (action?.uid === uidActionBanner) {
                    actionBanner = action;
                    return true;
                }

                return false;
            })
        );

        const completedAction = actionBanner?.status === TASK_STATUS.COMPLETED;

        return completedAction || !!phaseBanner?.completed;
    }, [executionFlow]);

    useAsyncEffect(async () => {
        if (Object.keys(task || {}).length) return;

        if (taskUid) {
            const foundTask = (tasks || []).find(
                (task) => task?.uid === taskUid
            );

            if (!!foundTask?.uid) {
                setTask(foundTask);
            } else {
                const newTask = await serviceTask.find({
                    taskUid,
                });

                if (typeof newTask === "string") return;

                services.tasks.addToList([newTask]);
                setTask(task);
            }
        } else if (taskProps) {
            setTask(taskProps);
        }
    }, [tasks, taskUid]);

    useEffect(() => {
        async function getExecutionFlow() {
            if (!task.executionFlow) return;

            try {
                const response = await serviceExecutionFlow.find({
                    executionFlowUid: task.executionFlow,
                });
                setExecutionFlow(response);
            } catch (error) {}
        }
        if (!!task) {
            getExecutionFlow();
        }

        return () => {};
    }, [task]);

    async function confirmReview() {
        setLoadingConfirgReview(true);
        await workfluxApi.tasks.confirmReview({ task: task?.uid });
        setLoadingConfirgReview(false);
    }

    const closeTask = () => {
        setTask(null);
        if (taskProps) {
            return;
        }
        const currentPath = history.location.pathname;

        if (currentPath.startsWith("/calendar/")) {
            history.push("/calendar");
        } else {
            history.push("/inbox");
        }
    };

    const handleLinkTask = () => {
        const executionFlowUid = task?.executionFlow;
        const type = task?.action?.type?.type;
        const uid = task?.uid;

        return `https://workflux.digital/executionFlow/${executionFlowUid}/task/${type}/${uid}`;
    };

    const submitTaskLink = () => {
        const baseUrl = "https://api.whatsapp.com/send?text=";
        const title = task?.action?.instructions || task?.action?.formTitle;

        if (task?.action?.hasFormReference) {
            const url = `A tarefa ${title}, do fluxo: ${handleLinkTask()}\n
  Formulário: ${title},\n
  Campos e valores do formulário\n`;

            window.open(`${baseUrl}${encodeURI(url)}`);
        } else {
            const url = `	A tarefa ${title} do fluxo ${handleLinkTask()}`;

            window.open(`${baseUrl}${encodeURI(url)}`);
        }
    };

    const handleRenderStep = (stepUid) => {
        const phase = task?.workflowObject?.phases.find(
            (phase) => phase.uid === stepUid
        );

        return phase?.name || "Etapa não preenchida";
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(handleLinkTask());
        setVisibleMessageLink(true);
        setTimeout(() => {
            setVisibleMessageLink(false);
        }, 2000);
    };

    const handleSms = async () => {
        if (auth.user.phone === "") {
            services.message.error({
                title: "Primeiro adicione seu contato na página de perfil",
            });
            return;
        }

        try {
            const number = `${auth.user.phoneCountry} ${auth.user.phoneArea} ${auth.user.phone}`;
            services.spinner.show();
            const response = await appcenterApi.sms.send(
                number,
                handleLinkTask()
            );
            services.spinner.hide();
            if (response.data) {
                services.message.success({ title: "SMS enviado com sucesso!" });
            } else {
                services.message.error({
                    title: "Não foi possível enviar por SMS!",
                });
            }
        } catch (error) {}
    };

    if (!task || (!taskUid && !taskProps)) return <></>;

    return (
        <>
            <div
                className={`${styles.detailTaskContainer} ${
                    !!taskUid || (!!taskProps && styles.detailTaskContainerOpen)
                }`}
            >
                <div className={`${styles.detailTask} ${styles[theme]}`}>
                    <div className={styles.floatButton}>
                        {task?.action?.agent !== auth.user.uid &&
                            auth.user.companyadmin && (
                                <Button
                                    icon={<Icon icon="faUser" />}
                                    color="primary"
                                />
                            )}
                        {task?.action?.agent === auth.user.uid && (
                            <Button
                                buttonIcon
                                icon={<Icon icon="faUsers" />}
                                onClick={() => setIsDelegateTask(true)}
                                color="primary"
                            />
                        )}
                    </div>
                    <section className={styles.infoTask}>
                        <section className={styles.headerButton}>
                            <strong>{i18n("Ações Rápidas")}</strong>
                            <Button buttonClose onClick={closeTask} />
                        </section>
                        <section className={styles.headerButtons}>
                            <Button
                                onClick={() => setIsVisibleFullFlow(true)}
                                iconLeft={<Icon icon="faDiagramProject" />}
                            />
                            <Button
                                onClick={() => setIsVisibleTaskBanner(true)}
                                disabled={!canVisibleTaskBanner}
                                iconLeft={<Icon icon="faFileCircleCheck" />}
                            />

                            <Button
                                onClick={() => setIsHistoryTask(true)}
                                iconLeft={<Icon icon="faClockRotateLeft" />}
                            />
                        </section>

                        <section className={styles.headerInfoTask}>
                            <strong>{i18n("Ações de compartilhamento")}</strong>
                            <section className={styles.headerButtons}>
                                <Button
                                    color="success"
                                    onClick={submitTaskLink}
                                    iconLeft={<Icon icon="faWhatsapp" />}
                                />
                                <div className={styles.areaCopy}>
                                    <Button
                                        color="success"
                                        onClick={handleCopy}
                                        iconLeft={<Icon icon="faLink" />}
                                    />
                                    {visibleMessageLink && (
                                        <p className={styles.copyText}>
                                            link copiado
                                        </p>
                                    )}
                                </div>
                                <Button
                                    color="success"
                                    onClick={handleSms}
                                    iconLeft={<Icon icon="faSms" />}
                                />
                            </section>

                            <StatusCanceled status={task?.status} />

                            {task?.status === "waiting-review" &&
                                task?.action?.agent === auth.user.uid && (
                                    <>
                                        <section
                                            className={styles.reviewTaskText}
                                        >
                                            <p>
                                                {i18n(
                                                    "Esta tarefa está em revisão devido a uma reprovação na fase posterior."
                                                )}
                                            </p>
                                            <p>
                                                {i18n(
                                                    "Você pode modificar os dados ou confirmar que os dados estão em conformidade"
                                                )}
                                            </p>
                                        </section>

                                        <Button
                                            fullWidth={false}
                                            loading={loadingConfirgReview}
                                            onClick={confirmReview}
                                            title="Confirmar revisão utilizando dados atuais"
                                        />
                                    </>
                                )}
                        </section>
                        <section className={styles.details}>
                            <div className={styles.titleTask}>
                                <span>
                                    <Icon icon="faDiagramProject" />
                                </span>
                                <strong>
                                    {task?.workflowObject?.description}
                                </strong>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>{i18n("Etapa")}:</strong>
                                <p>{handleRenderStep(task.phase)}</p>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>{i18n("Tarefa")}:</strong>
                                <p>
                                    {task?.action?.formTitle ||
                                        task?.action?.instructions}
                                </p>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>{i18n("Solicitante")}:</strong>
                                <p>
                                    {renderName({
                                        uid: executionFlow?.createdBy,
                                        users,
                                    })}
                                </p>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>
                                    {i18n("Responsável pela Solicitação")}:
                                </strong>
                                <p>{task?.action?.agentName}</p>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>{i18n("Prazo")}:</strong>
                                <p>
                                    {task?.action?.expirationDateString ||
                                        i18n("Prazo não determinado")}
                                </p>
                            </div>
                            <div className={styles.tableItem}>
                                <strong>{i18n("Instrução")}:</strong>
                                <p>
                                    {task?.action?.description ||
                                        i18n("Sem instruções")}
                                </p>
                            </div>
                        </section>
                        <section>
                            <AvatarProfileAndInfo
                                email={task?.action?.agentEmail}
                                userName={task?.action?.agentName}
                                profileUrl={null}
                            />
                        </section>
                        {typesTask[type]?.component}
                    </section>
                </div>
            </div>

            {isVisibleFullFlow && (
                <FullFlow
                    task={task}
                    close={() => setIsVisibleFullFlow(false)}
                    open={isVisibleFullFlow}
                />
            )}

            {isVisibleTaskBanner && (
                <TaskBanner
                    close={() => setIsVisibleTaskBanner(false)}
                    executionFlowUid={executionFlow?.uid}
                    actionBanner={executionFlow?.workflowObject?.actionBanner}
                    open={isVisibleTaskBanner}
                />
            )}

            <DelegateResponsibility
                isVisible={isDelegateTask}
                close={() => setIsDelegateTask(false)}
                taskUid={task.uid}
            />

            {isHistoryTask && (
                <HistoryTask
                    open={isHistoryTask}
                    close={() => setIsHistoryTask(false)}
                    executionFlowUid={task.executionFlow}
                />
            )}
        </>
    );
}
